//TODO:AME REPLACE TEXT OF TITLE WITH DATOCMS CONTRIBUTION.. SEE COMMENTED CODE

import React, {useState} from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"
import * as styles from "./keolis-location.module.scss";
import Layout from "../../components/layout/layout";
import mapLocation from "../../assets/images/maplocation.png";
import mailLocation from "../../assets/images/mailLocation.png";
import phoneLocation from "../../assets/images/phoneLocation.png";
import courrierLocation from "../../assets/images/courrierLocation.png";
import map from "../../assets/images/map.png"
import list from "../../assets/images/list.png"
import {graphql} from "gatsby";
import {KeolisLocationQuery} from "../../../graphql-types";
import PageCover from "../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../components/common/links-group-side/links-group-side";
import useWindowWidth from "../../hooks/use-window-width";
import arrow from '../../assets/vectors/arrow.svg';
import {ReactSVG} from "react-svg";
import {
    DEFAULT_MAP_PARAMETERS,
    GOOGLE_MAP_API_PARAMETERS,
    MAP_CONTAINER_STYLE,
    MAP_CUSTOM_STYLE,
  } from "../../constant"
import MapControl from "./map-control";
import MarkersFiltered from "./markers-filtred";

type ReactProps = {
    data: KeolisLocationQuery
}

const KeolisLocationPage: React.FC<ReactProps> = ({data}) => {

    const regionInitialValue = data.datoCmsKeolisLocation.regionSelectorTitle;
    const departmentInitialValue = data.datoCmsKeolisLocation.departmentSelectorTitle;

    const isMobile = useWindowWidth() <= 1200;
    const { isLoaded } = useJsApiLoader(GOOGLE_MAP_API_PARAMETERS)
    
    const [subsidiariesList, setSubsidiariesList] = useState(
        data.datoCmsKeolisLocation.busLocationList
            .sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 )
    );
    const [openRegionModal, setOpenRegionModal] = useState(false);
    // test ter pour répondre a une RG métier, en mobile, la vue MAP n'est pas afficée par défaut
    const [mapView, setMapView] = isMobile ? useState(false) : useState(true);
    const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
    const [region, setRegion] = useState(regionInitialValue);
    const [department, setDepartment] = useState(departmentInitialValue);

    const [selectedNetwork, setSelectedNetwork] = useState(null)
    const [currentZoom, setZoomValue] = useState(null)
    const [isManuelZoom, setZoomManagement] = useState(false)
    const [customCenter, setCenter] = useState(null)

    const handleMarkerClick = (element) => {
      setRegion(element.region)
      setDepartment(element.department)
      const restOfLocationSelected = data.datoCmsKeolisLocation.busLocationList.filter(elem => elem.region == element.region && elem.department == element.department && elem.location.latitude !== element.location.latitude && elem.location.longitude !== element.location.longitude)
      const newList = [...[element], ...restOfLocationSelected]
      if(isMobile) {
        setMapView(false)
      }
      setSelectedNetwork(element)
      setSubsidiariesList(newList)
    }

    const displayMapConditions = isMobile ? (isLoaded && mapView) : isLoaded;

    const selectedParameters = data.datoCmsKeolisLocation.busLocationList.filter(element => element.region == region || element.department == department)
    const finalSelectedParameters = subsidiariesList.length == 1 ? subsidiariesList : selectedParameters
    

    
    // le zoom: si c'est vue mobile, 0.5 en plus de la valeur par defaut, web : si un filtre est mis, valeur initiale + 3.5 sinon valeur initiale
    const customZoom = isMobile ? finalSelectedParameters && finalSelectedParameters.length > 0 ? DEFAULT_MAP_PARAMETERS.mobileZoom + 2.3 : DEFAULT_MAP_PARAMETERS.mobileZoom :  (finalSelectedParameters && finalSelectedParameters.length > 0 ) ? DEFAULT_MAP_PARAMETERS.defaultZoom + 2.3 : DEFAULT_MAP_PARAMETERS.defaultZoom
    // le centre: si une filile séléctionnée depuis la map, lon/lat de la filiale, sinon long/lat du filtre sinon long/lat par default (france)
    const center = {
      lat: finalSelectedParameters && finalSelectedParameters.length == 1 ? finalSelectedParameters[0].location.latitude : 
      finalSelectedParameters && finalSelectedParameters.length > 1 ? finalSelectedParameters[0].regionLocation.latitude :
       DEFAULT_MAP_PARAMETERS.latitude,
      lng: finalSelectedParameters && finalSelectedParameters.length == 1  ? finalSelectedParameters[0].location.longitude :
      finalSelectedParameters && finalSelectedParameters.length > 1 ? finalSelectedParameters[0].regionLocation.longitude :
      DEFAULT_MAP_PARAMETERS.longitude,
    }
    let customStyles = []
    function handleZoomChanged() {
        let zoomGetted = null
        zoomGetted = this.getZoom()
        setZoomValue(zoomGetted)
        setZoomManagement(true)
        //this refers to Google Map instance
      }

      const getCurrentLocation = () => { 
        console.log('called')
        navigator.geolocation.getCurrentPosition(success, error)
      }
    
      function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        setCenter({
            lat: latitude,
            lng: longitude
        })
      }
      
      function error() {
        console.log("Unable to retrieve your location");
      }

      console.log(customZoom)
      console.log(currentZoom)

      if (currentZoom > 6.5) {
        customStyles.push({
            featureType: "administrative.province",
            elementType: "all",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "administrative.locality",
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#c0c0c0", visibility: "on" }],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#d3d3d3" }],
          }
          )
    }


    console.log('refreshed')


    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsKeolisLocation.seo} pageTemplate="nous trouver">
            <PageCover
                // title={data.datoCmsKeolisLocation.cover[0] && data.datoCmsKeolisLocation.cover[0].title}
                title={isMobile ? ['Nous ', <br />, 'trouver'] :'Nous trouver'}
                image={isMobile ? data.datoCmsKeolisLocation.cover[0] && data.datoCmsKeolisLocation.cover[0].mobileImage : data.datoCmsKeolisLocation.cover[0] && data.datoCmsKeolisLocation.cover[0].backgroundImageDevis}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsKeolisLocation.linksGroup}/>}
                    <div className={styles.content}>
                        <h1 className={styles.listTitle}>{data.datoCmsKeolisLocation.pageTitle}</h1>
                        <p className={styles.listSubtitle}>{data.datoCmsKeolisLocation.pageSubtitle}</p>
                        <div className={styles.locationWrapper}>
                            <div className={styles.selectorsContainerWrapper}>
                                <div className={styles.selectorsContainer}>
                                    <div className={styles.selector}>
                                        <button
                                            aria-label="Open or close region selection modal"
                                            className={openRegionModal ? styles.selectorButtonSelected : styles.selectorButton}
                                            onClick={() => {
                                                setOpenRegionModal(!openRegionModal)
                                                openDepartmentModal && setOpenDepartmentModal(false);
                                            }}
                                        >
                                            {region}
                                            <ReactSVG src={arrow} className={openRegionModal ? styles.arrowOpened : styles.arrow} />
                                        </button>
                                        {openRegionModal && displayRegionModal()}
                                    </div>
                                    <div className={styles.selector}>
                                        <button
                                            aria-label="Open or close department selection modal"
                                            className={openDepartmentModal ? styles.selectorButtonSelected : styles.selectorButton}
                                            onClick={() => {
                                                setOpenDepartmentModal(!openDepartmentModal);
                                                openRegionModal && setOpenRegionModal(false);
                                            }}
                                        >
                                            {department}
                                            <ReactSVG src={arrow} className={openDepartmentModal ? styles.arrowOpened : styles.arrow} />
                                        </button>
                                        {openDepartmentModal && displayDepartmentModal()}
                                    </div>
                                </div>
                                {
                                  isMobile && (
                                    <div className={styles.separatorContainer}>
                                        <div className={styles.subsidiaryNumber}>{subsidiariesList && subsidiariesList.length} Filiales</div>
                                        {
                                            !mapView &&(
                                                <div className={styles.bouttonContainer}>
                                                    <button className={styles.mapButton}><img src={map} alt="boutton pour afficher la map" onClick={()=>{
                                                        setMapView(true)
                                                        if(isMobile) {
                                                            setRegion(regionInitialValue)
                                                            setDepartment(departmentInitialValue)
                                                            setSubsidiariesList(data.datoCmsKeolisLocation.busLocationList
                                                                .sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 ))
                                                        }
                                                    }} /> <div role="button" className={styles.viewText} onClick={()=>{
                                                        setMapView(true)
                                                        if(isMobile) {
                                                            setRegion(regionInitialValue)
                                                            setDepartment(departmentInitialValue)
                                                            setSubsidiariesList(data.datoCmsKeolisLocation.busLocationList
                                                                .sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 ))
                                                        }
                                                }}> Voir la carte </div></button>
                                                </div>
                                            )
                                        }
                                        {
                                            mapView &&(
                                                <div className={styles.bouttonContainer}>
                                                    <button className={styles.mapButton}><img src={list} alt="boutton pour afficher la liste" onClick={()=>{setMapView(false)}} /> <div role="button" className={styles.viewText} onClick={()=>{setMapView(false)}}> Voir la liste </div></button>
                                                </div>
                                            )
                                        }
                                   </div>
                                  )
                                }
                                {!isMobile && renderSubsidiariesList()}
                            </div>
                            { displayMapConditions && (
                            <div className={styles.mapContainer}>
                             
                            <GoogleMap
                                mapContainerStyle={MAP_CONTAINER_STYLE}
                                center={ customCenter == null ? center : customCenter}
                                zoom={customCenter == null ? customZoom : 10.5}
                                onZoomChanged={handleZoomChanged}
                                options={{
                                    styles: MAP_CUSTOM_STYLE.concat(customStyles),
                                    mapTypeControl: false,
                                    streetViewControl: false,
                                    scaleControl: true,
                                    fullscreenControl: false,
                                    draggableCursor: "grab",
                                    zoomControl: true,
                                    zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
                                    maxZoom: 20,
                                    minZoom: isMobile ? 1 : 3,
                                    restriction: { latLngBounds: { north: 83.8, south: -57, west: -170, east: 180 } },
                                }}
                                >
                                   <MarkersFiltered
                                      transportNetworks={data.datoCmsKeolisLocation}
                                      selectedNetwork={selectedNetwork}
                                      onClick={handleMarkerClick}
                                      urlIconMarkerClusterer={data.datoCmsKeolisLocation.iconMarkerClusterer.url}
                                      displayCluster = {selectedNetwork === null || selectedNetwork.region != region || selectedNetwork.departement != department || isMobile}
                                    />
                                  <MapControl position="LEFT_TOP">
                                    <button className={styles.defaultButton}><img src={mapLocation} alt="boutton location"
                                        onClick={getCurrentLocation} 
                                     /></button>
                                  </MapControl>
                                </GoogleMap>
                            
                            </div>
                            )}
                             {isMobile && !mapView && renderSubsidiariesList()}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )

    /**
     * Render a list with the subsidiaries
     */
    function renderSubsidiariesList(): JSX.Element {

        return (
            <div className={styles.subsidiariesList}>
                {subsidiariesList && subsidiariesList.map((item, index) => {
                    let isSelectedItem = false
                    if(isMobile) {
                        if(selectedNetwork == null) {
                            isSelectedItem = false
                        } else {
                            isSelectedItem = selectedNetwork.location.longitude == item.location.longitude && selectedNetwork.location.latitude == item.location.latitude
                        }
                    }
                    return (
                        <div key={item.title + index} className={isSelectedItem ? styles.subsidiarySelected : styles.subsidiary}>
                            <div className={styles.col}>
                                <p className={styles.title}>{item.title}</p>
                                <div className={styles.adressContainer}>
                                    <div className={styles.imageContainer}><img src={courrierLocation} alt="image courrier" /></div>   
                                    <div className={styles.adressesContent}>
                                        <p className={styles.text}>{item.addressOne}</p>
                                        <p className={styles.text}>{item.addressTwo}</p>
                                    </div>
                                </div>
                                <div className={styles.adressContainer}>
                                    <div className={styles.imageContainer}><img src={mailLocation} alt="image email" /></div>   
                                    <div className={styles.adressesContent}>
                                        <p className={styles.link}>{item.email}</p>
                                    </div>
                                </div>
                                <div className={styles.adressContainer}>
                                    <div className={styles.imageContainer}><img src={phoneLocation} alt="image phone" /></div>   
                                    <div className={styles.adressesContent}>
                                        <p className={styles.link}>{item.phone}</p>
                                    </div>
                                </div>
                            </div>
                           <a role='button' className={styles.bouttonDemandeDevis} href={`/devis-location-car`}>
                               <p className={styles.demandeDevisText}>Demander un devis</p>
                            </a>
                        </div>
                    )
                })}
            </div>
        )
    }

    /**
     * Search for a subsidiary depending on the selected region and/or the selected department.
     *
     * @param reg
     * @param dept
     */
    function searchSubsidiaries(reg, dept) {
        let filterSubsidiaries = [];

        data.datoCmsKeolisLocation.busLocationList.sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 ).map(item => {
            if(reg !== regionInitialValue && dept !== departmentInitialValue) {
                if(item.region === reg && item.department === dept) {
                    filterSubsidiaries.push(item);
                }
            }
            if(reg !== regionInitialValue && dept === departmentInitialValue) {
                if(item.region === reg) filterSubsidiaries.push(item);
            }
            if(reg === regionInitialValue && dept !== departmentInitialValue) {
                if(item.department === dept) filterSubsidiaries.push(item);
            }
            if(reg === regionInitialValue && dept === departmentInitialValue) {
                filterSubsidiaries.push(item);
            }
        })
        setSubsidiariesList(filterSubsidiaries.sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 ))
        setOpenDepartmentModal(false);
        setOpenRegionModal(false);
    }

    /**
     * Display the region dropdown menu on click to the input
     */
    function displayRegionModal(): JSX.Element {
        const regions = [];
        data.datoCmsKeolisLocation.busLocationList.sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 ).forEach(item => {
            if (!regions.includes(item.region)) {
                regions.push(item.region)
            }
        })

        const reg = [...regions].sort();
        reg.unshift(regionInitialValue);

        return (
            <div className={styles.modal}>
                {reg.map((r, index) => {
                    return (
                        <button
                            aria-label="Select region"
                            key={r + index}
                            className={styles.modalOption}
                            onClick={() => {
                                setDepartment(departmentInitialValue)
                                setRegion(r)
                                searchSubsidiaries(r, departmentInitialValue)
                                setSelectedNetwork(null)
                            }}
                        >{r}</button>
                    )
                })}
            </div>
        )
    }

    /**
     * Display the department dropdown menu on click to the input
     */
    function displayDepartmentModal(): JSX.Element {
        const departments = [];
        if (region === regionInitialValue) {
            data.datoCmsKeolisLocation.busLocationList.sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 ).forEach(item => {
                if (!departments.includes(item.department)) {
                    departments.push(item.department)
                }
            })
        } else {
            data.datoCmsKeolisLocation.busLocationList.sort((a, b) => (parseInt(a.addressTwo.slice(0,5)) > parseInt(b.addressTwo.slice(0,5))) ? 1 : -1 ).forEach(item => {
                if (!departments.includes(item.department) && item.region === region) {
                    departments.push(item.department)
                }
            })
        }
        const dept = [...departments].sort()
        dept.unshift(departmentInitialValue)

        return (
            <div className={styles.modal}>
                {dept.map((d, index) => {
                    return (
                        <button
                            aria-label="Select department"
                            key={d + index}
                            className={styles.modalOption}
                            onClick={() => {
                                setDepartment(d)
                                searchSubsidiaries(region, d)
                                setSelectedNetwork(null)
                            }}
                        >{d}</button>
                    )
                })}
            </div>
        )
    }

}

export const fragment = graphql`
    fragment KeolisLocationContentFields on DatoCmsKeolisLocation {
        id
        seo {
          description
          title
        }
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            },
            mobileImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            },
            backgroundImageDevis {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        linksGroup {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            }       
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        departmentSelectorTitle
        regionSelectorTitle
        pageTitle
        pageSubtitle
        busLocationList {
          addressTwo
          department
          email
          id
          phone
          region
          addressOne
          title
          website
          location {
            latitude
            longitude
          }
          regionLocation {
            latitude
            longitude
          }
        }
        iconMarkerClusterer {
          url
        }
    }
`

export const pageQuery = graphql`
    query KeolisLocation($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }   
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
        datoCmsKeolisLocation(locale: { eq: $locale }) {
            ...KeolisLocationContentFields
        }
    }
`

export default KeolisLocationPage;